import Commons from "../Commons";

export const CarnivalAPI = {
    PassType: {
        Couple: 'Couple',
        Male: 'Male Stag',
        Female: 'Female Stag',
    },

    Alcohols: ['whiskey', 'vodka', 'gin', 'rum',
        'tequila', 'beer', 'red_wine', 'white_wine',
        'rose_wine', 'sparkling_wine', 'champagne', 'cocktails'],

    loadUserPassDetails: async (
        { pass_id, user_id = Commons.getUserData()?.id, couple_qty = 0, male_qty = 0, female_qty = 0, },
        clbk = (data) => {
            console.log('Pass Details Loaded: ', data);
        }
    ) => {
        const formData = new FormData();
        formData.append("pass_id", pass_id);
        formData.append("user_id", user_id);
        formData.append("couple_qty", couple_qty);
        formData.append("male_qty", male_qty);
        formData.append("female_qty", female_qty);

        const result = await Commons.doFetch('APIs/Carnival/PassDetails', formData);
        // console.log('Carol Usage Details: ', passID, passData, user, pass);

        const data = result?.data ? result?.data[0] : undefined;
        clbk(data);
        return data;
    },
    getFullViewList: (pass) => {
        const link = `/${pass?.club?.area.city_name}/${pass?.club?.area.name}/${pass?.club?.name}/carnival/${pass?.id}`.replaceAll(RegExp('\\s+', 'g'), '-');
        return link;
    }
}