import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import Commons from "../Commons";
import "./Carnival.css";

const CarnivalPassRefund = () => {
  const { order_id } = useParams();

  useEffect(() => {
    const form = document.getElementById(order_id);
    if (form && form.submit) {
      console.log('Submit Refund Form: ', form, form.submit);
      form.submit();
    }
  }, []);

  const statusClbk = Commons.URL.getFull(`/CarnivalPassRefundStatus/${order_id}`);
  console.log('Pay Status Callback: ', statusClbk);
  return (
    <div style={{ height: "100px", marginTop: "200px" }}>
      Submit Refund
      <form
        id={order_id}
        action={`${Commons.URL.Master}APIs/Carnival/refundCarnivalPass`}
        method="post"
        className="summery--page--pay--now"
      >
        <input type="hidden" name="order_id" value={order_id} />
        {<input type="hidden" name="clbk" value={statusClbk} />}

        <input
          className="summery--page--submit"
          type="submit"
          value="Refund Order..."
        />
      </form>
    </div>
  );
};

export default CarnivalPassRefund;
