import React from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

import "./Carnival.css";

const CarnivalPassRefundStatus = () => {
  const { order_id, } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  let result = { status: 'unknown' };
  let output = searchParams.get('output');
  if (output) {
    try {
      result = (JSON.parse(output));
    } catch (e) {
      console.log('JSON Parsing Error: ', e, output);
    }
  }

  const success = (result.status === true);
  const status = success ? 'SUCCEEDED' : 'FAILED';
  const statusStyle = { color: (success ? 'green' : 'red') };
  return (
    <div style={{ color: 'white', margin: 'auto', maxWidth: '600px', textAlign: 'center', marginTop: '100px' }}>
      <h3>The Refund for your Carnival Pass Order '{order_id}' has
        <div style={statusStyle}>{status}</div>
      </h3>
      {success && (
        <div>
          <Link to='/my-booking-history'>Review the Order Status</Link>
        </div>
      )}
      {!success && (
        <div>
          <Link to={`/refundCarnivalPass/${order_id}`}>Attempt to Refund Again</Link>
        </div>
      )}
    </div>
  );
};

export default CarnivalPassRefundStatus;
