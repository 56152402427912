import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import "./eventview.css";

import CarnivalPassBookingInput from "./CarnivalPassBookingInput";

export function CarnivalPassBookButton(props) {
    const pass = props.pass;

    const [state1, setState1] = useState({ bottom: false });
    const toggleDrawer1 = (anchor, open) => {
        // console.log('Anchor: ', anchor, open);
        setState1({ ...state1, [anchor]: open });
    }


    return (
        <>
            {["bottom"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <div className='event--about--us--details2' >
                        <button
                            onClick={
                                async (event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    toggleDrawer1(anchor, true);
                                }
                            }
                        > BOOK NOW</button>
                    </div>
                    <Drawer
                        anchor={anchor}
                        open={state1[anchor]}
                        onClose={() => toggleDrawer1(anchor, false)}
                        PaperProps={{
                            sx: {
                                top: '20px',
                                margin: "15px", // Adjust the margin values as needed
                                borderTopLeftRadius: "8px", // Set border-top-left-radius
                                borderTopRightRadius: "8px", // Set border-top-right-radius
                            },
                        }}
                    >
                        <div style={{
                            // maxHeight: '100%',
                            overflow: 'auto'
                        }}>
                            <div
                                style={{ textAlign: 'right', width: '100%' }}>
                                <button
                                    onClick={
                                        (event) => {
                                            toggleDrawer1(anchor, false);
                                            // alert('Close Booking Dialog');
                                        }
                                    }
                                >X</button>
                            </div>
                            <CarnivalPassBookingInput pass={pass} />
                        </div>
                    </Drawer>
                </React.Fragment>
            ))}
        </>
    );
}
