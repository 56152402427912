import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { CarnivalAPI } from "./CarnivalAPIs";
import "./eventview.css";
import { Carousel } from "antd";
import { AiOutlineCalendar } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import { BsAlarm } from "react-icons/bs";
import minus from './image/minus.png';
import { CarnivalPassBookButton } from "./CarnivalPassBookButton";

const url = "https://partywitty.com/master/assets/uploads/";

export default function CarnivalPassDetails(props) {
    const passProp = props?.pass;
    let { id, area, city, club } = useParams();
    const [pass, setPass] = useState([]);

    const [isContentVisible, setIsContentVisible] = useState(false);
    const handleDescriptionClick = () => {
        setIsContentVisible(!isContentVisible);
    };

    useEffect(() => {
        if (passProp) {
            id = passProp.id;
            setPass(passProp);
        }
    }, [passProp]);

    useEffect(() => {
        CarnivalAPI.loadUserPassDetails({ pass_id: id }, (pass) => {
            console.log('View Pass with Details: ', pass)
            setPass(pass);
        });
    }, [id]);

    const getDuartion = (startTimeStr, endTimeStr) => {
        let duration = '';
        if (startTimeStr && endTimeStr) {
            const [startHour, startMinute] = startTimeStr.split(/[:\s]/);
            const startPeriod = startTimeStr.split(' ')[1];
            const [endHour, endMinute] = endTimeStr.split(/[:\s]/);
            const endPeriod = endTimeStr.split(' ')[1];
            let start = new Date();
            let end = new Date();
            start.setHours((startPeriod === 'PM' ? 12 : 0) + (parseInt(startHour) % 12), parseInt(startMinute));
            end.setHours((endPeriod === 'PM' ? 12 : 0) + (parseInt(endHour) % 12), parseInt(endMinute));
            // If end time is earlier than start time, it means the event spans midnight
            if (end < start) {
                end.setDate(end.getDate() + 1);
            }
            const timeDifference = end - start;
            const hours = Math.floor(timeDifference / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            duration = (`${hours} hours ${minutes} minutes`);
        }
        return duration;
    }

    const buildList = (text) => {
        const items = text?.split(',');
        if (items?.length > 0) {
            return (
                <ul>
                    {items.map(item => {
                        return (<li>{item.trim()}</li>);
                    })}
                </ul>
            );
        } else {
            return undefined;
        }
    }

    if (!pass) {
        return (
            <div className="Artist-viewall-banner--img-8" style={{ backgroundColor: 'black', color: 'white' }}>
                <p>No content available</p>
            </div>
        );
    } else {
        return (
            <div style={{ backgroundColor: 'black', color: 'white' }}>
                <div className="event-view-all mt-2">
                    <div className="event-about-us-page" style={{ marginBottom: '10px' }}>
                        <div className="event-about-us-left">
                            <div className="Artist-viewall-banner-event">
                                <Carousel autoplay>
                                    {pass.icon ? (
                                        <div className="Artist-viewall-banner--img-8">
                                            <img alt="banner" src={url + pass.icon} />
                                        </div>
                                    ) : (
                                        <div className="Artist-viewall-banner--img-8">
                                            <p>No content available</p>
                                        </div>
                                    )}
                                </Carousel>


                            </div>
                        </div>
                        <div className="event-about-us-right">
                            <div className="event--about--us--details-8">
                                <div className="event--about--us--details1">
                                    <h2>
                                        {pass.name}
                                        <span> ({pass.type})</span>
                                    </h2>
                                    <p> {pass.clubName}</p>
                                </div>

                            </div>

                            <div className="event--about--us--event-time-detail">
                                <div className="event--about--us--details-8">
                                    <div>
                                        <p className="event--about--us--event-time-detail--11--p">
                                            <span>
                                                <AiOutlineCalendar /></span> {pass.event_date} | {pass.start_time}
                                        </p>
                                        <p className="event--about--us--event-time-detail--11--p">
                                            <span>  <MdLocationOn /></span>
                                            {pass.clubAddress}
                                        </p>
                                    </div>
                                    <div className="event--about--us--event-time-detail--1">
                                        <div className="event--about--us--event-time-detail--11">
                                            <span>
                                                <BsAlarm />
                                            </span>
                                            <div>
                                                <p className="event--about--us--event-time-detail--11-p">Duration</p>
                                                <p className="event--about--us--event-time-detail--11--p">{getDuartion(pass.start_time, pass.end_time)}</p>
                                            </div>
                                        </div>
                                        <CarnivalPassBookButton pass={pass} />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="event-about-us-page" style={{ width: "calc(100%-20px)", margin: 'auto', }}>
                        {pass.pricing?.map(
                            price => {
                                return (
                                    <div style={{ flex: '1', border: '1px solid', borderRadius: '10px', padding: '10px', textAlign: 'center' }}>
                                        <h3>{price.ticket_type}</h3>
                                        <div>
                                            Price: <span style={{ textDecoration: 'line-through' }}> {price.mrp} </span> <span style={{ fontWeight: 'bold' }}> {price.price} </span>
                                        </div>
                                        <div>
                                            Available Seats: <span style={{ textDecoration: 'line-through' }}> {price.seat} </span> <span style={{ fontWeight: 'bold' }}> {price.seat} </span>
                                        </div>
                                    </div>
                                )
                            }
                        )}
                    </div>

                    <div className="event-about-us-page-content" style={{ width: '100%', display: "flex", alignContent: 'center' }}>
                        <div className="event--about--us--About">
                            <h2>Alcoholic Beverages</h2>
                            <p >
                                {pass.alcoholic_beverages}
                            </p>
                            <ul>
                                <li>Whiskey</li>
                                {buildList(pass.whiskey)}
                                <li>Vodka</li>
                                {buildList(pass.vodka)}
                                <li>Gin</li>
                                {buildList(pass.gin)}
                                <li>Rum</li>
                                {buildList(pass.rum)}
                                <li>Tequila</li>
                                {buildList(pass.tequila)}
                                <li>Beer</li>
                                {buildList(pass.beer)}
                                <li>Red Wine</li>
                                {buildList(pass.red_wine)}
                                <li>White Wine</li>
                                {buildList(pass.white_wine)}
                                <li>Rose Wine</li>
                                {buildList(pass.rose_wine)}
                                <li>Sparkling Wine</li>
                                {buildList(pass.sparkling_wine)}
                                <li>Champagne</li>
                                {buildList(pass.champagne)}
                                <li>Cocktails</li>
                                {buildList(pass.cocktails)}
                            </ul>
                        </div>

                        <div className="event--about--us--About">
                            <h2>Non-Alcoholic Beverages</h2>
                            <div dangerouslySetInnerHTML={{ __html: pass.non_alcoholic_beverages }}></div>
                        </div>

                        <div className="event--about--us--About">
                            <h2>Starters</h2>
                            <div dangerouslySetInnerHTML={{ __html: pass.food_starter }}></div>
                        </div>

                        <div className="event--about--us--About">
                            <h2>
                                {!isContentVisible && <span onClick={handleDescriptionClick}>+</span>}
                                {isContentVisible && <span onClick={handleDescriptionClick}><img src={minus}></img></span>}
                                Terms & Conditions
                            </h2>
                            {isContentVisible && (
                                <div>
                                    <p>(i) Please carry a valid ID proof along with you.</p>
                                    <p>(ii) No refunds on purchased ticket/pass are possible, even in case of any rescheduling.</p>
                                    <p>(iii) Security procedures, including frisking remain the right of the management.</p>
                                    <p>(iv) No dangerous or potentially hazardous objects including but not limited to weapons, knives, guns, fireworks, helmets, lazer devices, bottles, musical instruments will be allowed in the venue and may be ejected with or without the owner from the venue.</p>
                                    <p>(v) The sponsors/performers/organizers are not responsible for any injury or damage occurring due to the event. Any claims regarding the same would be settled in respective city courts.</p>
                                    <p>(vi) People in an inebriated state may not be allowed entry.</p>
                                    <p>(vii) Organizers hold the right to deny late entry to the event.</p>
                                    <p>(viii) Venue rules apply.</p>
                                    <div dangerouslySetInnerHTML={{ __html: pass.tc }}></div>
                                </div>
                            )}
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}