import React, { useEffect, useState } from "react";
import "./Footer.css";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";
import Googleplay from "./image/Googleplay.png";
import Applestore from "./image/appdownloads.png";
import icon_call from './image/call-icon-gif.gif';
import icon_whatsapp from './image/whatsapp.gif';
import icon_fillform from './image/fillaform.jpeg';

const phoneNo = '+918299619469';

function QuickCallFooter() {
  return (
    <>
      <div class="quick-call-footer">
        <div class="f-row">
          <div class="f-col">
            <a
              href={`tel:${phoneNo}`}
              onclick="gtag_call_conversion()"
            >
              <img src={icon_call} alt="call" />
              <br /> Call Now</a>
          </div>
          <div class="f-col">
            <a
              href={`https://api.whatsapp.com/send?phone=${phoneNo}&text=Hello, I am interested in PartyWitty, Please get in touch...`}
              onclick="gtag_whatsapp_conversion()"
            >
              <img src={icon_whatsapp} alt="whatsapp" />
              <br />  WhatsApp
            </a>
          </div>
          <div class="f-col">
            <Link
              to="/our-contact-page"
            // data-bs-toggle="modal"
            // data-bs-target="#exampleModal"
            >
              <img src={icon_fillform} alt="fill form" />
              <br />
              Enquire Now
            </Link>
          </div>

        </div>
      </div>
    </>
  );
}

function Footer() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [carnivalTypes, setCarnivalTypes] = useState([]);
  useEffect(() => {
    const activeCarnivalTypes = "https://partywitty.com/master/Api/getData/activeCarnivalTypes";
    fetch(activeCarnivalTypes)
      .then((response) => response.json())
      .then((response) => {
        console.log('All Active Carnival Types: ', response);

        if (response.status === "true") {
          setCarnivalTypes([]);
        } else {
          let types = response.data || [];
          setCarnivalTypes(types);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  return (
    <>
      <div className="footer-card--1">
        <div className="footer-card">
          <div className="footer">
            <div className="footer-content">
              <div className="logo1">
                <h2>PARTY WITTY</h2>
              </div>
              <div class="footer-content2">
                <div className="footer-anchor">
                  <a href="https://www.facebook.com/party.witt?mibextid=ZbWKwL">
                    <FaFacebookF />
                  </a>
                  <a href="https://instagram.com/partywitty_?igshid=NzZhOTFlYzFmZQ==">
                    <BsInstagram />
                  </a>
                  <a href={`https://wa.me/${phoneNo}`}>
                    <BsWhatsapp />
                  </a>
                </div>
              </div>
            </div>

            <div className="footer-content">
              <h3>Menu</h3>
              <ul>
                <Link to="/PartyWitty-Home">
                  <li>HOME</li>
                </Link>
              </ul>
              <ul>
                <li>
                  Carnivals
                  {carnivalTypes?.length > 0 && carnivalTypes.map(ct => {
                    return (
                      <Link to={`/carnival/${ct.name}`}>
                        <ul>
                          <li>{ct.name}</li>
                        </ul>
                      </Link>
                    );
                  })}

                </li>
              </ul>

              <ul>
                <Link to="/party-witty-aboutus">
                  <li>ABOUT US</li>
                </Link>
              </ul>
              <ul>
                <Link to="/services">
                  <li>SERVICES</li>
                </Link>
              </ul>
              <ul>
                <Link to="/blog">
                  <li>BLOG</li>
                </Link>
              </ul>

              <Link to="/our-contact-page">
                <ul>
                  <li>CONTACT US</li>
                </ul>
              </Link>
              {/**
              <Link to="/club-detail">
                <ul>
                  <li>Club-detail-page</li>
                </ul>
              </Link>
               */}
            </div>

            <div className="footer-content">
              <h3>Agreements</h3>

              <ul>
                <Link to="/club--about--us">
                  <li>CLUB AGREEMENT</li>
                </Link>
              </ul>
              <ul>
                <Link to="/artist-agreement">
                  <li>ARTIST AGREEMENT</li>
                </Link>
              </ul>
              <ul>
                <Link to="/refund-policy">
                  <li>REFUND & CANCELLATION POLICY</li>
                </Link>
              </ul>
              <ul>
                <Link to="/privacy-policy">
                  <li>PRIVACY POLICY</li>
                </Link>
              </ul>

              <ul>
                <Link to="/term-conditions-policy">
                  <li>TERMS & CONDITION</li>
                </Link>
              </ul>
            </div>
            <div className="footer-content--1">
              <h3>Partner with Us</h3>
              <ul className="footer-content-ul1">
                <Link to="/as-an-artist">
                  <li>AS AN ARTIST</li>
                </Link>
              </ul>
              <ul className="footer-content-ul2">
                <Link to="/Product-Pricing">
                  <li classname="footer--as-a-buisness">AS A BUSINESS </li>
                </Link>
              </ul>

            </div>
            <div className="footer-content">
              <h3>Download</h3>
              <div className="playstore">
                <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                  <img src={Googleplay} alt="playstore" />
                </Link>
                <Link to="https://apps.apple.com/in/app/partywitty/id6478442502">
                  <img src={Applestore} alt="appstore" />
                </Link>
              </div>
            </div>
          </div>
          <div class="footer-card2">
            <div class="footer-card2--1">
              <Link to="/term-conditions-policy">
                <p>Terms & Conditions</p></Link>
              <Link to="/privacy-policy">
                <p>Privacy Policy</p></Link>
            </div>
            <Link to="/">
              <p>PARTYWITTY.COM</p></Link>
          </div>
        </div >
      </div >
      <div className="footer-mobile-view">
        <div className="footer-social-links"
          style={
            {
              marginBottom: '90px'
            }
          }>
          <div class="footer-content2">
            <div className="footer--content--1--2">
              <h1 className="follow-header">PARTY WITTY</h1>
              <div className="social-links">
                <a
                  href="https://www.facebook.com/party.witt?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://instagram.com/partywitty_?igshid=NzZhOTFlYzFmZQ=="
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsInstagram />
                </a>
                <a href={`https://wa.me/${phoneNo}`} target="_blank" rel="noreferrer">
                  <BsWhatsapp />
                </a>
              </div>
            </div>

            <div className="menu-links">
              <Link to="/" className="menu-links-links">
                Home
              </Link>

              {carnivalTypes?.length > 0 && carnivalTypes.map(ct => {
                return (
                  <Link to={`/carnival/${ct.name}`}>
                    <li>{ct.name} Carnival</li>
                  </Link>
                );
              })}

              <Link to="/party-witty-aboutus" className="menu-links-links">
                About us
              </Link>

              <Link to="/services" className="menu-links-links">
                Services
              </Link>


              <Link to="/blog">
                <li>Blog</li>
              </Link>

              <Link to="/our-contact-page" className="menu-links-links">
                Contacts us
              </Link>


              <h1 className="partner">Agreements</h1>
              <Link to="/club-agreement" className="menu-links-links">
                <li>Club Agreement</li>
              </Link>
              <Link to="/artist-agreement" className="menu-links-links">
                <li>Artist Agreement</li>
              </Link>
              <Link to="/refund-policy" className="menu-links-links">
                <li>Refund & Cancellation Policy</li>
              </Link>
              <Link to="/privacy-policy" className="menu-links-links">
                <li>Privacy Policy</li>
              </Link>

              <h1 className="partner">Partner with Us</h1>
              <Link to="/as-an-artist" className="menu-links-links">
                As an Artist
              </Link>

              <Link to="/Product-Pricing" className="menu-links-links">
                As a Buisness
              </Link>




              <div className="playstore-mobile">
                <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                  <img src={Googleplay} alt="playstore" />
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                  <img src={Applestore} alt="appstore" />
                </Link>
              </div>
            </div>
            <div class="footer-btm">
              <p>© AMIRAG HOSPITALITY (OPC) PVT. LTD., All Right Reserved.</p>
              <p>Designed By PARTY WITTY </p>
            </div>
          </div>
        </div>
        <QuickCallFooter />
      </div>
    </>
  );
}

export default Footer;
