import React, { useEffect, useState } from "react";
import "./MyBooking.css";
import { Link } from "react-router-dom";
import Commons from "./../Commons";

const url = "https://partywitty.com/master/assets/uploads/";
const userdata = JSON.parse(localStorage.getItem("userData"));

export default function CarnivalPassBookings(props) {
  const { expired = false } = props;
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const formdata = new FormData();
      formdata.append("user_id", userdata ? userdata.id : null);
      formdata.append("expired", expired);

      try {
        const response = await Commons.doFetch('APIs/Carnival/carnivalPassTransaction', formdata, 'POST');

        const bookingHistory = response.data;
        setBookings(bookingHistory);
        // console.log('Carnival Booking History Refreshed: ', bookingHistory.length);
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="booking-history-card">
      {bookings && bookings.length > 0 ? (
        bookings.map((booking) => (
          <>
            <CarnivalPassBookingCard booking={booking} expired={expired} />
          </>
        ))
      ) : (
        <p>You have no bookings.</p>
      )}
    </div>
  );
}

function CarnivalPassBookingCard(props) {
  const expired = props.expired;
  const pBooking = props.booking;
  const [booking, setBooking] = useState(pBooking);
  const order_id = booking.order_id;
  const user_id = booking.user_id;

  const loadPassOrderDetails = async () => {
    const formdata = new FormData();
    formdata.append("user_id", user_id);
    formdata.append("order_id", order_id);
    // console.log('Pass Booking: ', booking);

    try {
      const response = await Commons.doFetch(
        "APIs/Carnival/carnivalPassTransactionDetails",
        formdata,
        'POST'
      );
      const bookingDetails = response.data;
      setBooking(bookingDetails?.length > 0 ? bookingDetails[0] : pBooking);
      console.log('Carnival Pass Booking : ', bookingDetails, booking);
    } catch (error) {
      console.error("Error fetching carnival pass details:", error);
    }
  };

  useEffect(() => {
    // fetchData();
  }, []);

  const initCashRefund = async () => {
    const isOK = window.confirm('Initiate CASH REFUND for order - ' + order_id);
    if (isOK) {
      const target = `/refundCarnivalPass/${order_id}`;
      window.location.href = target;
    }
  }

  const initCreateVoucher = async () => {
    const isOK = window.confirm('Generate VOUCHER agsinst order - ' + order_id);
    if (isOK) {
      const formdata = new FormData();
      formdata.append("user_id", user_id);
      formdata.append("order_id", order_id);

      const result = await Commons.doFetch(`APIs/Carnival/convertCarnivalPassOrderToCoupon`, formdata, 'POST');
      console.log('Coupon Result: ', result);
      await loadPassOrderDetails();
    }
  }

  const couple_qty = parseInt(booking.couple_qty) || 0;
  const male_qty = parseInt(booking.male_qty) || 0;
  const female_qty = parseInt(booking.female_qty) || 0;
  const closedStates = ['converted_to_coupon', 'refunded', 'refund_inited'];
  const isPaid = (['success', ...closedStates].includes(booking.status));
  const clubRejected = (['rejected'].includes(booking.club_accept_status));
  const isClosed = (closedStates.includes(booking.status));

  return (
    <div
      className="web--view--Notification--bar--heading--and--cross--card"
      key={booking.id}
    >
      <div className="web--view--Notification--bar--heading--and--cross--card1">
        <div className="web-view-img">
          <img src={url + booking.passIcon} alt="Booking" />
        </div>
        <div className="web--view--Notification--bar--heading--and--cross--card1--1">
          <div className="transaction-history-card">
            <p className="web--view--notification--1--p">
              {booking.passName}
            </p>
            <p className="web--view--notification--1--p1">
              On {booking.eventDate} {booking.startTime} @ {booking.clubName}, {booking.clubAddress}
            </p>
            <p className="web--view--notification--1--p">
              {couple_qty > 0 && <span>{couple_qty} Couple </span>}
              {male_qty > 0 && <span>{male_qty} Male </span>}
              {female_qty > 0 && <span>{female_qty} Female </span>}
            </p>
          </div>
          <div className="web--view--Notification--bar--heading--and--cross--card2">
            <p className="web--view--notification--1--p">
              Order Id: {order_id}
            </p>
            <p className="web--view--notification--1--p1">
              Payment Status:  <span className="upper">{booking.status}</span>
            </p>
            {(!isPaid && !expired) &&
              <p className="web--view--notification--1--p1">
                <Link to={`/payCarnivalPass/${order_id}`}>Attempt to Pay Again</Link>
              </p>
            }
            {(isPaid) &&
              <div className="web--view--notification--1--p1">
                Club Acceptance: <span className="upper">{booking.club_accept_status}</span>
                {(clubRejected && !isClosed) && (
                  <div className="web--view--notification--1--p1">
                    I want to <button onClick={initCashRefund}>Get Cash Refund</button> of my payment or <button onClick={initCreateVoucher}>Use in Future</button>
                  </div>
                )}
                {booking.club_accept_status === 'accepted' && (
                  <>
                    <button>Update Guest List</button>
                  </>
                )}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}