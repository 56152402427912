import "../App.css";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import Carousel from "react-bootstrap/Carousel";
import React from "react";
import banner3 from "../Club About Us/assets/images2/banner3.png";
import { FaRegHeart } from "react-icons/fa";
import { Base64 } from "js-base64";

function Clubaboutuspagebanneruser(props) {
  const [data, setData] = useState(props.details || []);
  const { city, area, clubname } = useParams();
  const clubN = clubname;

  const [responseMessage, setResponseMessage] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [bookdata, setBookData] = useState([]);
  const [active, setActive] = useState(1);
  const [index, setIndex] = useState(0);
  const [id, setId] = useState(data?.id);
  // console.log('Club ID: ', id, data);

  const cardCount = document.querySelectorAll(".card-container11").length;
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [banner, setBanner] = useState([]);

  var url = "https://partywitty.com/master/assets/uploads/";

  const newApi = new FormData();
  newApi.append("city", city);
  newApi.append("area", area);
  newApi.append("club", clubN);

  useEffect(() => {
    axios
      .post("https://partywitty.com/master/APIs/Web/getClubDetails", newApi)
      .then((response) => {
        setData(response.data?.data);
        const id = response.data?.data?.id;

        setId(id);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const clubid = new FormData();
    clubid.append("club_id", id);
    // console.log('Get Club Banners: ', id, clubid);
    if (id === undefined) {
      return;
    }

    axios
      .post("https://partywitty.com/master/Api/getDataPost/clubBanner", clubid)
      .then((response) => {
        // console.log('Club Banners: ', response);

        if (response.data.status === "true") {
          setBanner([{ image: "1696913971_75db3e21d0d3c227ceea.png" }]);
        } else {
          setBanner(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);


  const updateCarousel = () => {
    document.querySelectorAll(".card-container11").forEach((element, i) => {
      const offset = ((active - i + cardCount) % cardCount) / 3;
      const direction = Math.sign(active - i);
      const absOffset = Math.abs(active - i) / 3;
      const isActive = i === active ? 1 : 0;
      const opacity = Math.abs(active - i) <= 1 ? 1 : 0;


      element.style.setProperty("--offset", offset);
      element.style.setProperty("--direction", direction);
      element.style.setProperty("--abs-offset", absOffset);
      element.style.setProperty("--active", isActive);
      element.style.setProperty("--opacity", opacity);
    });
  };


  useEffect(() => {
    updateCarousel();
  }, [active]);
  const [active2, setActive2] = useState(false);
  const handleClick = () => {
    setActive2(!active2);
  };
  const addtocart = async (e) => {


    try {
      if (!userdata) {

        setResponseMessage("User data is missing");
        return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append("club_id", id);
      formDataToSend.append("user_id", userdata.id);

      const response = await fetch(
        "https://partywitty.com/master/Api/sendData/addClubFavourite",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      const responseData = await response.json();

      if (responseData.status === true) {
        setResponseMessage(responseData.msg);
        setBookData(responseData.data);

        if (responseData.data) {

        } else {
          setShowResponse(true);
        }
      } else {
        setResponseMessage("Error in processing the request");
      }
    } catch (error) {
      setResponseMessage("An error occurred");
    }
  };

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [dataid, setDataid] = useState([]);
  const [heartActive, setHeartActive] = useState(false);

  const clubid1 = new FormData();
  var url = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {

    if (userdata && userdata.id) {
      clubid1.append("user_id", userdata.id);

      axios
        .post(
          "https://partywitty.com/master/Api/getDataPost/getUserFavouriteClub",
          clubid1
        )
        .then((response) => {
          if (response.data.status === "true") {

            setDataid([]);
          } else {
            const dataIds = response.data.data.map((item) => item.club_id);
            setDataid(dataIds);
            setHeartActive(dataIds.includes(id));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const [active1, setActive1] = useState(false);
  const handleClick1 = () => {
    setActive1(!active1);
  };
  return (
    <div className="first-image">
      <div className="fav--icon--design--club">

      </div>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        controls={false}
        interval={6000}
        indicators={false}
      >
        {banner &&
          banner.map((e) => (
            <Carousel.Item className="slider_img1">
              {e.img ? (
                <img src={url + e.img} alt="img" />
              ) : (
                <img src={banner3} alt="img" />
              )}
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
}

export default Clubaboutuspagebanneruser;
