import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from './Table';
import Package from './Package';
import HistoryTable from './HistoryTable';
import HistoryPackage from './HistoryPackage';
import CarnivalPassBookings from './CarnivalPassBookings';
import HistoryCarnivalPassBookings from './HistoryCarnivalPassBookings';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, mt: '30px' }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BookingTabs() {
  const [value, setValue] = React.useState(0);
  const [upcomingTabValue, setUpcomingTabValue] = React.useState(0);
  const [historyTabValue, setHistoryTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUpcomingChange = (event, newValue) => {
    setUpcomingTabValue(newValue);
  };

  const handleHistoryChange = (event, newValue) => {
    setHistoryTabValue(newValue);
  };
  console.log('Booking Tabs Refreshed: ');

  return (
    <Box sx={{ width: "100%", borderColor: "white", color: "white" }}>
      <Box sx={{ borderBottom: 1, borderColor: "white", color: "white" }}>
        <Tabs value={value} onChange={handleChange} aria-label="main tabs example" sx={{ borderBottom: 1, borderColor: "white", color: "white" }}>
          <Tab label="Upcoming" sx={{ color: "white" }} style={{ minWidth: "50%" }} {...a11yProps(0)} />
          <Tab label="History" sx={{ color: "white" }} style={{ minWidth: "50%" }} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} sx={{ borderBottom: 1, borderColor: "white", color: "white" }} index={0} >
        <Tabs value={upcomingTabValue} onChange={handleUpcomingChange} sx={{ borderBottom: 1, borderColor: "white", color: "white" }} aria-label="upcoming tabs example">
          <Tab label="Table" {...a11yProps(0)} sx={{ color: "white" }} style={{ minWidth: "33%" }} />
          <Tab label="Package" {...a11yProps(1)} sx={{ color: "white" }} style={{ minWidth: "33%" }} />
          <Tab label="Carnival Passes" {...a11yProps(2)} sx={{ color: "white" }} style={{ minWidth: "33%" }} />
        </Tabs>
        <CustomTabPanel value={upcomingTabValue} index={0}>
          <Table />
        </CustomTabPanel>
        <CustomTabPanel value={upcomingTabValue} index={1}>
          <Package />
        </CustomTabPanel>
        <CustomTabPanel value={upcomingTabValue} index={2}>
          <CarnivalPassBookings />
        </CustomTabPanel>
      </CustomTabPanel>
      <CustomTabPanel value={value} sx={{ borderBottom: 1, borderColor: "white", color: "white" }} index={1}>
        <Tabs value={historyTabValue} onChange={handleHistoryChange} sx={{ borderBottom: 1, borderColor: "white", color: "white" }} aria-label="history tabs example">
          <Tab label="Table" {...a11yProps(0)} sx={{ color: "white" }} style={{ minWidth: "33%" }} />
          <Tab label="Package" {...a11yProps(1)} sx={{ color: "white" }} style={{ minWidth: "33%" }} />
          <Tab label="Carnival Passes" {...a11yProps(2)} sx={{ color: "white" }} style={{ minWidth: "33%" }} />
        </Tabs>
        <CustomTabPanel value={historyTabValue} index={0}>
          <HistoryTable />
        </CustomTabPanel>
        <CustomTabPanel value={historyTabValue} index={1}>
          <HistoryPackage />
        </CustomTabPanel>
        <CustomTabPanel value={historyTabValue} index={2}>
          <HistoryCarnivalPassBookings />
        </CustomTabPanel>
      </CustomTabPanel>
    </Box>
  );
}
