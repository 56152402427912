import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import "./eventview.css";
import { AiOutlineCalendar } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import { BsAlarm } from "react-icons/bs";
import { Carousel } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { AiTwotoneCalendar } from "react-icons/ai";
import { MdOutlineWatchLater } from "react-icons/md";
import minus from './image/minus.png';


export default function EventAboutUS() {

  const { city, area, club, title } = useParams();

  const [startTimeStr, setStartTimeStr] = useState("");
  const [endTimeStr, setEndTimeStr] = useState("");
  const [duration, setDuration] = useState("");
  const [eventdetails, setEventdetails] = useState([]);
  const [eventdetailsbanner, setEventdetailsbanner] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude1, setLatitude1] = useState(null);
  const [longitude1, setLongitude1] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [recEventDetails, setRecEventDetails] = useState([]);
  const [activeVideo, setActiveVideo] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const videoRef = useRef(null);
  var url = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude.toString());
          setLongitude(longitude.toString());
          fetchEvents(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
          alert("Please enable Geo Location Service to find events details.");
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude1(latitude.toString());
          setLongitude1(longitude.toString());
          fetchEvents1(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);
  const handleMouseLeave = (videoId) => {
    videoId.pause();
    setActiveVideo(null);
  };
  const toggleMute = () => {
    const video = videoRef.current;
    if (video) {
      video.muted = !video.muted;
      setIsMuted(video.muted);
    }
  };
  const fetchEvents = async (latitude, longitude) => {
    const formdata = new FormData();
    formdata.append("latitude", latitude);
    formdata.append("longitude", longitude);
    formdata.append("city", city);
    formdata.append("area", area);
    formdata.append("club", club);
    formdata.append("title", title);
    try {
      const response = await axios.post(
        "https://partywitty.com/master/APIs/Web/eventDetails",
        formdata
      )
      const eventData = response.data.data;
      console.log('Event Detail: ', eventData);

      setEventdetails(eventData);
      setEventdetailsbanner(response.data.data);
      setStartTimeStr(eventData.event_time);
      setEndTimeStr(eventData.event_end_time);
      setEventId(eventData.id);
      fetchEvents1(latitude, longitude, eventData.id);
    }
    catch (error) {
      console.error("Error fetching event details:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (startTimeStr && endTimeStr) {
      const [startHour, startMinute] = startTimeStr.split(/[:\s]/);
      const startPeriod = startTimeStr.split(' ')[1];
      const [endHour, endMinute] = endTimeStr.split(/[:\s]/);
      const endPeriod = endTimeStr.split(' ')[1];
      let start = new Date();
      let end = new Date();
      start.setHours((startPeriod === 'PM' ? 12 : 0) + (parseInt(startHour) % 12), parseInt(startMinute));
      end.setHours((endPeriod === 'PM' ? 12 : 0) + (parseInt(endHour) % 12), parseInt(endMinute));
      // If end time is earlier than start time, it means the event spans midnight
      if (end < start) {
        end.setDate(end.getDate() + 1);
      }
      const timeDifference = end - start;
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      setDuration(`${hours} hours ${minutes} minutes`);
    }
  }, [startTimeStr, endTimeStr]);



  const [isContentVisible, setIsContentVisible] = useState(false);
  const handleDescriptionClick = () => {
    setIsContentVisible(!isContentVisible);
  };
  const fetchEvents1 = async (latitude1, longitude1, eventId) => {
    const formdata = new FormData();
    formdata.append('latitude', latitude1);
    formdata.append('longitude', longitude1);
    formdata.append('event_id', eventId);

    try {
      const response = await axios.post(
        'https://partywitty.com/master/APIs/ClubEvent/recommendedEvents',
        formdata
      );
      const eventData = response.data.data;
      setRecEventDetails(eventData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching event details:', error);
      setIsLoading(false);
    }
  };
  const eventUrl = (e) => {
    return `/${(e.cityName?.replace(/ /g, "-") || "").toLowerCase()}/${(
      e.areaName?.replace(/ /g, "-") || ""
    ).toLowerCase()}/${(
      e.clubName?.trim().split(/ +/).join("-").replace(/ /g, "-") || ""
    ).toLowerCase()}/event/${(
      e.name?.trim().split(/ +/).join("-").replace(/ /g, "-") || ""
    ).toLowerCase()}`;
  };
  const handleVideoClick = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };
  if (!eventdetails) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="event-view-all mt-2">
        <div className="event-about-us-page">
          <div className="event-about-us-left">
            <div className="Artist-viewall-banner-event">
              <Carousel autoplay>
                {eventdetails.video ? (
                  <div className="Artist-viewall-banner--img-8">
                    <video
                      ref={videoRef}
                      controls
                      onClick={handleVideoClick}
                    >
                      <source src={url + eventdetails.video} type="video/mp4" />
                    </video>
                  </div>
                ) : eventdetails.eventBanners && eventdetails.eventBanners.length > 0 ? (
                  eventdetails.eventBanners.map((banner, index) => (
                    <div className="Artist-viewall-banner--img-8" key={index}>
                      <img alt={`banner-${index}`} src={url + banner.img} />
                    </div>
                  ))
                ) : eventdetails.image ? (
                  <div className="Artist-viewall-banner--img-8">
                    <img alt="banner" src={url + eventdetails.image} />
                  </div>
                ) : (
                  <div className="Artist-viewall-banner--img-8">
                    <p>No content available</p>
                  </div>
                )}
              </Carousel>


            </div>
          </div>
          <div className="event-about-us-right">
            <div className="event--about--us--details-8">
              <div className="event--about--us--details1">
                <h2>
                  {eventdetails.name}
                  <span> &nbsp; &nbsp;( {eventdetails.categoryName})</span>
                </h2>
                <p> {eventdetails.clubName}</p>
              </div>

            </div>

            <div className="event--about--us--event-time-detail">
              <div className="event--about--us--details-8">
                <div>
                  <p className="event--about--us--event-time-detail--11--p">
                    <span>
                      <AiOutlineCalendar /></span> {eventdetails.event_date} | {eventdetails.event_time}
                  </p>
                  <p className="event--about--us--event-time-detail--11--p">
                    <span>  <MdLocationOn /></span>
                    {eventdetails.clubAddress}
                  </p>
                </div>
                <div className="event--about--us--event-time-detail--1">
                  <div className="event--about--us--event-time-detail--11">
                    <span>
                      <BsAlarm />
                    </span>
                    <div>
                      <p className="event--about--us--event-time-detail--11-p">Duration</p>
                      <p className="event--about--us--event-time-detail--11--p">{duration}</p>
                    </div>
                  </div>
                  <Link to={`/event-book/${eventdetails.club_id}/${eventdetails.id}`} style={{ textDecoration: "none" }}>
                    <div className="event--about--us--details2">
                      <button>Book Now</button>
                    </div>
                  </Link>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div className="event-about-us-page-content">
          <div className="event--about--us--About">
            <h2>About</h2>
            <p dangerouslySetInnerHTML={{ __html: eventdetails.event_des }}></p>
            <p >Live events are dynamic and engaging experiences that bring people together in real-time, offering an unparalleled sense of immediacy and community. These events span a wide array of formats and purposes, including concerts, sports games, theater performances, festivals, conferences, and webinars. Each type of live event provides unique opportunities for attendees to connect, learn, celebrate, or simply enjoy entertainment. <br />
              <br />One of the primary appeals of live events is the energy and excitement that permeate the atmosphere. Whether it’s the electric buzz of a packed stadium cheering for their team or the intimate connection felt in a small theater during a powerful performance, the live aspect creates a shared experience that recorded media simply cannot replicate. The unpredictability of live events also adds to their charm; anything can happen, making each moment unique and memorable. <br />
              <br />
              Live events also foster a sense of community and belonging. They bring together individuals with shared interests, creating opportunities for networking, collaboration, and forming lasting relationships. For businesses and organizations, live events are an invaluable tool for engaging with their audience, showcasing products or services, and building brand loyalty. Conferences and trade shows, for instance, offer platforms for industry professionals to exchange ideas, discover new trends, and expand their professional networks.</p>
          </div>

          <div className="event--about--us--About">
            <h2>
              {!isContentVisible && <span onClick={handleDescriptionClick}>+</span>}
              {isContentVisible && <span onClick={handleDescriptionClick}><img src={minus}></img></span>}
              Terms & Conditions
            </h2>
            {isContentVisible && (
              <div>
                <p>(i) Please carry a valid ID proof along with you.</p>
                <p>(ii) No refunds on purchased ticket/pass are possible, even in case of any rescheduling.</p>
                <p>(iii) Security procedures, including frisking remain the right of the management.</p>
                <p>(iv) No dangerous or potentially hazardous objects including but not limited to weapons, knives, guns, fireworks, helmets, lazer devices, bottles, musical instruments will be allowed in the venue and may be ejected with or without the owner from the venue.</p>
                <p>(v) The sponsors/performers/organizers are not responsible for any injury or damage occurring due to the event. Any claims regarding the same would be settled in respective city courts.</p>
                <p>(vi) People in an inebriated state may not be allowed entry.</p>
                <p>(vii) Organizers hold the right to deny late entry to the event.</p>
                <p>(viii) Venue rules apply.</p>
              </div>
            )}
          </div>

        </div>
        <div className="event--about--us--About-recommended">
          <h2>Recommended Event</h2>
          <div>
            <div className="club_Card_Container">
              {isLoading ? (
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '20px',
                    padding: '4rem',
                    color: '#fff',
                    textTransform: 'uppercase',
                  }}
                >
                  Loading...
                </p>
              ) : recEventDetails && recEventDetails.length === 0 ? (
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '20px',
                    padding: '4rem',
                    color: '#fff',
                    textTransform: 'uppercase',
                  }}
                >
                  No Event Available
                </p>
              ) : (
                recEventDetails &&
                recEventDetails.map((e, index) => (
                  <div
                    key={e.id}
                    className="event-view-card1"
                    onClick={() => (window.location.href = eventUrl(e))}
                  >
                    {e.image ? (
                      <img src={url + e.image} alt="image" />
                    ) : e.video ? (
                      <video
                        id={index}
                        controls
                        loop
                        onClick={toggleMute}
                        onMouseLeave={() =>
                          handleMouseLeave(document.getElementById(index))
                        }
                      >
                        <source src={url + e.video} type="video/mp4" />
                      </video>
                    ) : (
                      <p>No content available</p>
                    )}
                    <div className="event-view-card1-content">
                      <div className="event-view-card1-content1">
                        <h4 className="p-0 m-0">{e.name}</h4>
                        <p className="event-view-card1-content1-p">{e.clubName}</p>
                        <p className="event-view-card1-content1-p1">{e.distance}</p>
                      </div>
                      <div className="event-view-card1-content2">
                        <div className="date">
                          <span>
                            <AiTwotoneCalendar />
                          </span>
                          <p>{e.event_date}</p>
                        </div>
                        <div className="date">
                          <span>
                            <MdOutlineWatchLater />
                          </span>
                          <p>{e.event_time}</p>
                        </div>
                        <button className="event-view-all-button">Book Now</button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}
