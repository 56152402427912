const Commons = {
    Format: {
        Currency: { decimalCount: 2 }
    },
    formToJson: (formData) => {
        var object = {};
        formData.forEach(function (value, key) {
            object[key] = value;
        });
        return object;
    },
    URL: {
        Master: 'https://partywitty.com/master/',
        Upload: 'https://partywitty.com/master/assets/uploads/',
        getFull: (path) => {
            const href = window.location.href;
            const cPath = window.location.pathname;
            const prefix = href.replace(cPath, '');
            const fullPath = `${prefix}${path}`;
            return fullPath;
        },
    },
    getUserData: () => {
        const ud = JSON.parse(localStorage.getItem("userData"));
        return ud;
    },
    getUploadPath: (filePath) => {
        return `${Commons.URL.Upload}${filePath}`;
    },

    doFetch: async (api, formData, method = 'GET', headers = {}) => {
        if (!api.startsWith('http')) {
            api = `${Commons.URL.Master}${api}`;
        }
        const init = {
            method: formData ? 'POST' : 'GET',
            // withCredentials: true,
            // crossorigin: true,
            // mode: 'no-cors',
            ...headers,
            body: formData,
        };

        try {
            const response = await fetch(api, init);

            // console.log('Fetch: ', api, init, response, response?.json);

            if (response?.json) {
                const data = await response.json();
                return data;
            }
        } catch (e) {
            console.log('Error Fetch: ', e);
        }

        return undefined;
    },

    toRad: (num) => {
        return num * Math.PI / 180;
    },

    calculateDistance: (lat1, lon1, lat2, lon2) => {
        var R = 6378137; // meters
        var dLat = Commons.toRad((lat2 - lat1));
        var dLon = Commons.toRad((lon2 - lon1));
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(Commons.toRad(lat1)) * Math.cos(Commons.toRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    },

    findDistance: async (lat, lon) => {
        const geo = await Commons.getMyGeo();
        if (geo) {
            const dist = Commons.calculateDistance(lat, lon, geo.latitude, geo.longitude);
            return Math.round(dist / 1000); // in KM
        } else {
            return undefined;
        }
    },

    getMyGeo: () => {
        return new Promise((resolve, reject) => {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve(position.coords);
                    },
                    (error) => {
                        console.error("Error getting location:", error);
                        reject(error);
                    }
                );
            } else {
                reject("Geolocation is not enabled in this browser.");
            }
        });
    }

}

export default Commons;